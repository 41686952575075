.carousel {
    background-image: url('/Assets/Blue-Linen.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3rem 0rem 6rem 2rem;
    position: relative;
    overflow: hidden;

    @include media(">=tablet") {
        width: 90%;
        margin: 8rem 0 8rem auto;
        padding: 8rem 0 8rem 8rem;
    }

    &--alt {
        background-image: url('/Assets/carousel-bg-alt.png');
    }

    &-heading {
        display: flex;
        flex-direction: row;

        .carousel-navigation {
            position: relative;
            width: 12rem;
            height: 10rem;
            display: none;
			right: 15%;

            @include media(">=tablet") {
                display: block;
            }

            .swiper-button-prev {
                color: $white;
                width: 4rem;
                height: 7rem;
                border-right: 1px solid rgba(255, 255, 255, .35);
                padding-right: 1rem;

                &--alt {
                    border-right: 1px solid $black;
                    color: $black;
                }
            }

            .swiper-button-next {
                color: $white;
                width: 4rem;
                height: 7rem;

                &--alt {
                    color: $black;
                }
            }

			.swiper-button-next,
			.swiper-button-prev {
				&::after {
					font-size: 2rem;
				}
			}
        }
    }

    &-container {
        overflow: hidden;
    }

    &-slide {

        height: 190px!important;
        padding-bottom: 6rem;

        @include media(">=tablet") {
            padding-bottom: 3rem;
        }

        @include media(">=desktop") {
            height: 300px!important;
        }

        p {
            font-size: 1.4rem;
            color: $black;
            margin-top: .5rem;
        }

        &--tag {
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
	a {
		font-family: $font-sans-serif;
		font-weight: bold;
		text-decoration: none;
	}

    .products-swiper-pagination {
        position: absolute;
        bottom: 20px!important;
        right: 0;
        width: fit-content!important;
        margin: 0 auto;

        @include media(">=tablet") {
            display: none;
        }

        .swiper-pagination-bullet {
            width: 1rem;
            height: 1rem;
            background: transparent;
            border: 2px solid #EBEBEB;
        }

        .swiper-pagination-bullet-active {
            background: $white;
        }

        &--alt {

            .swiper-pagination-bullet { 
                border: 2px solid $black;
            }

            .swiper-pagination-bullet-active {
                background: $black;
            }
        }
    }
}