html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
	background-color: $white;
}

// Font styles //

h1 {
	font-size: 5.5rem;
	font-weight: 600;
	font-family: $font-serif;
}

h2 {
	font-size: 4.5rem;
	font-weight: 600;
	font-family: $font-serif;
}

h3 {
	font-size: 3.8rem;
	font-weight: normal;
	font-family: $font-serif;
}

h4 {
	font-size: 3.1rem;
	font-weight: normal;
	font-family: $font-serif;
}

h5 {
	font-size: 2.5rem;
	font-weight: normal;
	font-family: $font-serif;
}

h6 {
	font-size: 2.5rem;
	font-weight: normal;
	margin: 15px 0;
	font-family: $font-serif;
}

a {
	font-weight: normal;
	font-family: $font-sans-serif;
	color: $slate-blue;
}

p {
	font-size: 1.6rem;
	font-weight: normal;
	font-family: $font-sans-serif;

	> a {
		&:hover {
			text-decoration: underline;
		}
	}
}

th,
td {
	font-family: $font-sans-serif;
}

label,
input {
	font-family: $font-sans-serif-alt;
}

ul {
	list-style: none;
	padding: 0;

	li {
		font-weight: normal;
		font-family: $font-sans-serif;
		font-size: 1.4rem;
		position: relative;
		margin-bottom: 2rem;
		padding-left: 2rem;
	}
}
