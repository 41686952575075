.brands {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 2.5rem 0;
	background: url("/Assets/bg-linen-light.png");

	@include media(">=tablet") {
		flex-direction: row;
		padding: 5rem;
	}

	&__heading {
		width: unset;

		@include media(">=tablet") {
			width: 35%;
		}
	}

	&__image-container {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 50%;
			bottom: 0; left: 50%;
			transform: translateX(-50%);
			background: linear-gradient(0deg, rgba(49,62,72,1) 0%, rgba(255,255,255,0) 100%);
		}

		img {
			width: 100%;
			height: 100%;
		}

		.img-content {
			position: absolute;
			bottom: 0;
			left: 2rem;
			color: $white;

			h2 {
				font-size: 2.9rem;
				margin-top: 0;
			}

			p {
				margin: 0.5rem 0;
			}
		}
	}

	&__content {
		width: unset;
		margin: 2rem 0;

		@include media(">=tablet") {
			width: 35.5%;
			margin: 0 2.5rem;
		}

		.content-container {
			padding: 0 2rem;

			p {
				min-height: 8rem;
				margin-top: 4rem;
			}
		}
	}
}
