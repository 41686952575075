.two-col {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@include media(">=tablet") {
		flex-direction: row;
		margin: 5rem 0;
		background-position: top left;
		background-size: cover;
	}

	&--products {
		background-image: url("/Assets/products-background.png");
	}

	&--alt {
		margin-bottom: 0;
	}

	&--no-margin {
		margin: 0;
	}

	&__col {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
		
		img {
			width: 100%;
			height: 100%;
		}

		.copy-container {
			width: 85%;
			margin: 0 auto;
			padding: 2.5rem 0;
			color: $black;
			text-align: center;

			@include media(">tablet") {
				padding: 5rem 0;
			}

			hr {
				width: 8rem;
				height: 0.4rem;
				margin: 0 auto;
				background-color: $black;
			}

			h2 {
				font-size: 2.5rem;
				text-transform: uppercase;

				@include media(">=tablet") {
					font-size: 3.5rem;
				}
			}

			&--alt {
				max-width: 50rem;
				color: $white;
				position: absolute;
				padding: 2.5rem;
				left: 0;
				right: 0;

				hr {
					background-color: $white
				}
			}
		}

		&--video {
			background-image: none;

			.copy-container {
				text-align: left;

				p {
					margin: 0;
					font-size: 1.4rem;
				}
			}
		}

		@include media(">=tablet") {
			width: 50%;
			padding: 0;
		}

		&--alt {
			height: 43rem;
			width: 100%;

			&:nth-child(1) {
				border-bottom: 5px solid #fff;

				@include media(">=tablet") {
					border-bottom: none;
				}
			}

			@include media(">=tablet") {
				width: 49.8%;
			}
		}
	}

	&__img {
		display: none;
		width: 100%;

		&--brands {
			display: block;

			&::before {
				content: "";
				position: absolute;
				width: 25%;
				height: 100%;
				top: 0; left: 0;
				background: linear-gradient(90deg, rgba(49,62,72,1) 0%, rgba(49,62,72,0) 100%);
			}
		}

		&--1916 {
			display: block;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include media(">=tablet") {
			display: block;
			width: 100%;
			height: 100%;
		}

		&--tabs {
			display: block;
			height: 54rem;

			.tab-img {
				display: none;
				object-fit: cover;
				
				&.active {
					display: block;
				}
			}
		}
	}

	&-tabs {
		padding: 2rem;

		@include media(">=tablet") {
			width: 50%;
			padding: 5rem 0rem 5rem 8rem;
		}

		h2 {
			font-size: 2.5rem;

			@include media(">=tablet") {
				font-size: 3.5rem;
			}
		}

		.tab-content-item {
			display: none;
		}

		.tab-content-item.active {
			display: block;
		}
	}

	&--brands {
		background-color: rgba(83,86,90,.9);
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;

		.copy-container {
			color: $white;
			text-align: left;
			width: 80%;

			hr {
				width: 8rem;
				height: 0.4rem;
				margin: 0;
				background-color: $white;
			}
		}

		.two-col__img {
			display: block;
		}
	}

	&--tabs {
		background-image: none;
	}
}

.product-tabs {
	display: flex;
	width: 100%;
	width: fit-content;

	&--alt {
		margin: 5rem auto;
	}

	&__tab {
		font-size: 1.7rem;
		padding: 15px 4rem;
		cursor: pointer;
	}

	&__tab.active {
		color: $light-blue;
		border: 1px solid #e0e0e0;
		border-bottom: none;
	}
}

.cards__container--details {
	display: none;
}

.cards__container--details.active {
	display: flex;
	flex-direction: column;

	@include media(">=tablet") {
		flex-direction: row;
	}
}

.tab-content {
	max-width: 500px;
	margin-top: 25px;

	p {
		margin: 4rem 0;
	}
}

.tab-content-item {
	display: none;
}

.tab-content-item.active {
	display: block;
}