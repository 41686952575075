.button {
	position: relative;
	display: inline-flex;
	align-items: center;
	font-size: 1.7rem;
	color: $white;
	background-color: $slate-blue;
	text-decoration: none;
	padding: 0rem 4rem 0rem 2rem;
	height: 5.7rem;
	vertical-align: middle;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&::before {
		content: "";
		position: absolute;
		background-image: url("/Assets/icons/arrow-icon.png");
		background-repeat: no-repeat;
		background-position: center;
		width: 2rem;
		height: 2rem;
		right: 1rem;
	}

	&--grey {
		background-color: $dark-grey;
	}

	&--gold {
		background-color: $gold;
	}

	&--transparent {
		background-color: transparent;
		border: 1px solid $white;

		&:hover {
			background: $slate-blue;
			color: $white;
			border: 1px solid $slate-blue;

			&::before {
				content: "";
				background-image: url("/Assets/icons/cta-arrow-white.svg");
			}
		}

		&--alt {
			background-color: transparent;
			border: 1px solid $black;
			color: $black;

			&::before {
				content: "";
				background-image: url("/Assets/icons/cta-arrow.svg");
			}

			@include media(">=tablet") {
				background-color: transparent;
				border: 1px solid $white;
				color: $white;

				&::before {
					content: "";
					background-image: url("/Assets/icons/arrow-icon.png");
				}
			}
		}

		&-black {
			border: 1px solid $black;
			color: $black;
			background-color: transparent;

			&::before {
				content: "";
				position: absolute;
				background-image: url("/Assets/icons/cta-arrow.svg");
				background-repeat: no-repeat;
				background-position: center;
				width: 2rem;
				height: 2rem;
				right: 1rem;
			}
		}
	}

	&--black-hover {

		&:hover {
			background-color: $white;
			color: $black;

			&::before {
				content: "";
				position: absolute;
				background-image: url("/Assets/icons/cta-arrow.svg");
				background-repeat: no-repeat;
				background-position: center;
				width: 2rem;
				height: 2rem;
				right: 1rem;
			}
		}
	}

	&--1916 {
		&:hover {
			color: $white;
			background-color: $gold;
			border-color: $gold;

			&::before {
				background-image: url("/Assets/icons/cta-arrow-white.svg");
			}
		}
	}
}

.heading-container {
	margin: 2rem 1rem;
	padding: 0 1rem;

	@include media(">=tablet") {
		margin-left: 7rem;
		margin-top: 8rem;
	}

	hr {
		width: 8rem;
		height: 0.4rem;
		background-color: $black;
		margin: 0 auto 0 0;
	}
	.heading {
		font-size: 2.5rem;
		margin: 2rem 0;
		text-transform: uppercase;

		@include media(">=tablet") {
			font-size: 3.5rem;
		}
	}

	.subheading {
		font-size: 2rem;
		font-weight: bold;
		margin: 1rem 0;

		@include media(">=tablet") {
			font-size: 2rem;
		}
	}

	&--alt {
		text-align: left;
		margin-top: 4rem;

		@include media(">=tablet") {
			text-align: center;
		}

		hr {
			margin-right: auto;

			@include media(">=tablet") {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&--solo {
		text-align: center;

		hr {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&--brands {
		margin: 2rem 1rem;

		@include media(">=tablet") {
			margin-left: 7rem;
			margin-top: 20rem;
		}
	}

	&--carousel {
		margin: 0;
		color: $white;
		width: 100%;
		margin-bottom: 4rem;
		padding: 0;

		@include media(">=tablet") {
			width: 45rem;
			margin-right: auto;
		}

		hr {
			background-color: $white;
		}

		p {
			font-size: 1.4rem;

			@include media(">=tablet") {
				font-size: 1.6rem;
			}
		}
	}

	&--carousel-alt {
		margin: 0 0 4rem 0;
		padding: 0 1rem;
		color: $black;
		width: 100%;

		@include media(">=tablet") {
			width: 45rem;
			margin-right: auto;
		}

		hr {
			background-color: $black;
			border: none;
		}

		p {
			font-size: 1.4rem;

			@include media(">=tablet") {
				font-size: 1.6rem;
			}
		}
	}

	&--hero {
		margin: 0;
		color: $white;
		padding: 0 2rem;

		hr {
			background-color: $white;
		}

		.heading {
			font-size: 2.5rem;
			text-transform: uppercase;

			@include media(">=tablet") {
				font-size: 3.5rem;
			}
		}

		p {
			font-size: 1.4rem;

			@include media(">=tablet") {
				font-size: 1.6rem;
			}
		}
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.button__openToggle {
	display: block;
	background: none;
	font-size: 1rem;
	border: none;
	width: 2rem;
	background: transparent;
    position: relative;
    transform: rotate(90deg);
    transition: .2s ease-out;
    width: 2rem;
	height: 2rem;
	color: transparent;
	padding: 0;
	cursor: pointer;

	&::before, &::after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #000;
	}

	&::after {
		transform: rotate(270deg);
	}

	&:hover {
		&::before, &::after {
			background: $gold;
		}
	}
}