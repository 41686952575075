.site-footer {
    width: 100%;

    .footer-main {
        background-color: $dark-grey;
        padding: 5rem 0;

        .footer-logo {
            width: 60%;
            margin: 0 auto;
            padding: 0rem 3rem 2rem 3rem;
            border-width: 0 0 2px 0;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #53565a, #fff, #53565a);

            @include media(">=tablet") {
                width: 24rem;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .logo-container {
            width: 80%;
            display: flex;
            margin: 3rem auto;
            flex-direction: row;
            align-items: baseline;

            @include media(">=tablet") {
                width: 35rem;
                margin: 5rem auto;
            }

            .logo {
                margin: 0 auto;
                max-width: 14rem;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .footer-nav {
            width: 75%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;

            @include media(">=tablet") {
                width: fit-content;
                margin: 3rem auto;
            }

            ul {
                list-style: none;
                color: $white;
                width: 50%;
                display: flex;
                flex-direction: column;

                @include media(">=tablet") {
                    flex-direction: row;
                    width: initial;
                    padding: 0;
                }

                li {
                    font-size: 1.6rem;
                    line-height: 1.6;

					&::before {
						display: none;
					}

                    @include media(">=tablet") {
                        line-height: 1;
                        padding: 0 2rem;
                    }

                    a {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }

        .social-section {
            display: flex;
            flex-direction: column;
            text-align: center;

            @include media(">=tablet") {
                flex-direction: row;
                width: fit-content;
                margin: 0 auto;
            }

            p {
                color: $white;
                text-transform: uppercase;
                font-weight: 600;
            }

            .social-icons {
                display: flex;
                flex-direction: row;
                justify-content: center;

                @include media(">=tablet") {
                    padding: 0 2rem;
                }

                .social-icon {
                    width: 4rem;
                    height: 4rem;
                    padding: 0 0.25rem;
                    background-position: center;
                    background-repeat: no-repeat;
            
                    &--facebook {
                        background-image: url('/Assets/icons/facebook-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/facebook-hover.png');
                        // }
                    }
            
                    &--pinterest {
                        background-image: url('/Assets/icons/pinterest-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/twitter-hover.png');
                        // }
                    }
            
                    &--houzz {
                        background-image: url('/Assets/icons/houzz-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/linkedin-hover.png');
                        // }
                    }

                    &--architizer {
                        background-image: url('/Assets/icons/architizer-new-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/linkedin-hover.png');
                        // }
                    }

                    &--x {
                        background-image: url('/Assets/icons/x-icon.png');
                    }
            
                    &--instagram {
                        background-image: url('/Assets/icons/instagram-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/youtube-hover.png');
                        // }
                    }

                    &--linkedin {
                        background-image: url('/Assets/icons/linkedin-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/youtube-hover.png');
                        // }
                    }

                    &--youtube {
                        background-image: url('/Assets/icons/youtube-icon.png');
                        // &:hover {
                        //     background-image: url('/Assets/icons/youtube-hover.png');
                        // }
                    }
                }
            }
        }
    }

    .footer-secondary {
        background-color: $white;
        text-align: center;
        display: flex;
        flex-direction: column;

        @include media(">=tablet") {
            flex-direction: row;
            align-items: center;
        }

        .legal-container {
            margin: 3rem 0;

            @include media(">=tablet") {
                margin-left: 5rem;
            }

            a {
                color: $dark-grey;
                text-decoration: none;
                font-size: 1.2rem;
                font-weight: 10000;
                padding: 0 .5rem;
            }
        }

        p {
            font-size: 1.2rem;
            color: $dark-grey;

            @include media(">=tablet") {
                margin: 0 0 0 auto;
            }
        }

        .secondary-logo {
            width: 10rem;
            margin: 0 auto;

            @include media(">=tablet") {
                margin: 0 6rem;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}