.banner {
    background-image: url("/Assets/products-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    &__copy {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        padding: 4rem 1rem;

        @include media(">=tablet") {
            padding: 8rem 0;
            width: 30%;
        }

        hr {
            width: 8rem;
            height: .4rem;
            margin: 0 auto;
            background-color: $black;
        }

        h2 {
            font-size: 2.5rem;
            text-transform: uppercase;
            @include media(">=tablet") {
                font-size: 3.5rem;
            }
        }
    }
}