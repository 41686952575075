.hero {
    display: flex;
    flex-direction: column;

    @include media(">=tablet") {
        flex-direction: row;
        min-height: 45rem;
    }

    &--about {
        margin: 2rem;
		flex-direction: column;

		.hero__image {
			order: 0;
		}

		.hero__copy {
			order: 1;
		}

		@include media(">tablet") {
			flex-direction: row;
            width: 85%;
            margin: 8rem auto;

			.hero__image,
			.hero__copy {
				order: unset;

                p {
                    margin: 4rem 0;
                }
			}
		}
    }

    &__copy {
        width: 100%;
        background-image: url('/Assets/Blue-Linen.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 3rem 0 7rem;

        @include media(">=tablet") {
            width: 30%;
            padding: 6rem 3rem;
            display: flex;
            align-items: center;
        }

        &--about {
            width: 100%;
            background-image: none;
			align-items: flex-start;
           
            @include media(">=tablet") {
                width: 40%;
                padding: 0 3rem;
            }

            .heading-container {
                margin: 0 auto;
				padding: 0 2rem;

                @include media(">=tablet") {
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }

    &__visual {
		position: relative;
        width: 100%;
        background-color: $light-grey;

        @include media(">=tablet") {
            width: 70%;
			min-height: 20rem;
        }

		img {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

        .button-container {
            position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
            color: $white;
			text-shadow: 0 0 12px $black;
			cursor: pointer;

            .play-button {
                background-image: url('/Assets/icons/play-button.svg');
                background-size: contain;
                background-repeat: no-repeat;
				background-color: transparent;
                width: 10rem;
                height: 10rem;
                border: none;
                cursor: pointer;
            }
        }
    }

    &__image {
        width: 100%;

        img {
            width: 100%;
        }

        @include media(">=tablet") {
            width: 60%;
            margin: 0 auto;
        }

        &--alt {
            img {
                width: 100%;
            }
    
            @include media(">=tablet") {
                max-width: 68rem;
                margin: 0 auto;
            }
        }
    }

    &-quickdrain {
        height: 55rem;
        flex-direction: column;

        @include media(">=tablet") {
            height: 45.4rem;
            flex-direction: row;
        }

        &-copy {
			position: relative;
            width: 100%;
            color: $black;
            margin-right: 1rem;

			&::before {
				content: "";
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				margin: auto;
				width: 100%;
				height: 100%;
				background: linear-gradient(90deg, rgba(49,62,72,1) 0%, rgba(255,255,255,0) 100%);
				opacity: .75;
			}

            @include media(">=tablet") {
                width: 60%;
                color: $white;
            }
    
            .copy-container {
                position: absolute;
                max-width: 42rem;
                padding: 2rem;
                margin-top: 20rem;

                @include media(">=tablet") {
                    padding: 8rem;
                    margin-top: initial;
                }
    
                h1 {
                    font-size: 3.5rem;
                    text-transform: uppercase;
                }
            }
    
            img {
                width: 100%;
                height: auto;

                @include media(">=tablet") {
                    height: 100%;
                }
            }
    
            .img-title {
                position: absolute;
                bottom: 0;
                right: 3rem;
                font-size: 2rem;
                color: $white;
				display: none;

				@include media(">tablet") {
					display: block;
				}

				&--mobile {
					right: 0;
					left: 0;
					display: block;
					background-color: rgba(50,98,149,.67);
					font-size: 1.4rem;
					text-align: center;
					margin: 0;
					padding: 1rem .5rem;

					@include media(">tablet") {
						display: none;
					}
				}
            }
        }
    
        &-img-container {
            position: relative;
            width: 20%;
            margin-right: 1rem;
            display: none;

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 20%;
				bottom: 0; left: 50%;
				transform: translateX(-50%);
				background: linear-gradient(0deg, rgba(128,128,128,1) 0%, rgba(255,255,255,0) 100%);
			}

            @include media(">=tablet") {
                display: block;
            }

            &:nth-last-child(1) {
                margin-right: 0;
            }
    
            img {
                width: 100%;
                height: 100%;
            }
    
            .img-title {
                position: absolute;
                bottom: 0;
				width: 100%;
				text-align: center;
                font-size: 2rem;
                color: $white;
            }
        }
    }

    &-1916 {
        height: 45.4rem;
        flex-direction: column;

        @include media(">=tablet") {
            flex-direction: row;
        }

        &-copy {
            width: 100%;
            position: relative;
            color: $black;
            margin-right: 1rem;

            @include media(">=tablet") {
                color: $white;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 70%;
					height: 100%;
					background: linear-gradient(90deg, rgba(49,62,72,1) 0%, rgba(49,62,72,0) 100%);
					opacity: .75;
				}
            }
    
            .copy-container {
                position: absolute;
                max-width: 42rem;
                padding: 2rem;
                margin-top: 10rem;

                @include media(">=tablet") {
                    padding: 8rem;
                    margin-top: initial;
                }
    
                h1 {
                    font-size: 3.5rem;
                }
                
                p {
                    font-size: 1.6rem;
                }
            }
    
            img {
                width: 100%;
                height: auto;

                @include media(">=tablet") {
                    height: 100%;
					object-fit: cover;
                }
            }
        }
    }
}