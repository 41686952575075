.cards {
	width: 100%;

	&__heading {
		position: relative;
		font-family: $font-sans-serif;
		font-size: 2rem;
		font-weight: 300;
		color: $black;
		margin-left: 2rem;

		&::before {
			content: "";
			position: absolute;
			width: 5.4rem;
			height: .4rem;
			background-color: $black;
			top: -1rem;
		}

		@include media(">=tablet") {
			font-size: 3.5rem;
			margin: 4rem;
			text-align: center;

			&::before {
				display: none;
			}
		}
	}

	&__container {
		width: 100%;
		padding-bottom: 8rem !important;

		@include media(">=tablet") {
			display: flex;
			flex-direction: row;
			padding-bottom: 0 !important;
			justify-content: space-between;
		}

		.swiper-wrapper {
			@include media(">=tablet") {
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		&--alt {
			@include media(">=tablet") {
				width: 70%;
				margin: 0 auto;
			}

			.swiper-wrapper {
				@include media(">=tablet") {
					flex-wrap: wrap;
				}
			}
		}

		&--details {
			width: 90%;
			margin: 0 auto;

			@include media(">=tablet") {
				width: 100%;
			}
		}

		&--1916 {
			justify-content: center;
		}
	}

	&__swiper-pagination {
		position: absolute;
		bottom: 4rem !important;
		right: 0;
		width: fit-content !important;
		margin: 0 auto;

		@include media(">=tablet") {
			display: none;
		}

		.swiper-pagination-bullet {
			width: 1rem;
			height: 1rem;
			background: transparent;
			border: 2px solid #707070;
		}

		.swiper-pagination-bullet-active {
			background: #326295;
			border-color: $slate-blue;
		}
	}

	.feature-card {
		display: block;
		text-align: center;

		@include media(">=tablet") {
			width: 24%;

			&:nth-child(4n) {
				margin-right: 0;
			}
		}

		h2 {
			font-size: 2.5rem;
			text-transform: uppercase;
		}

		&--alt {
			@include media(">=tablet") {
				width: 31%;
				margin-right: 2rem;
				margin-bottom: 2rem;

				&:nth-last-child(1) {
					margin-right: 0;
				}
			}
		}

		&__img {
			width: 100%;
			height: auto;
		}

		&__video {
			width: 100%;
			height: 28rem;
		}

		&__copy-container {
			padding: 2rem;

			h2 {
				font-size: 2.3rem;
				color: $black;
				text-transform: uppercase;
			}

			.copy {
				font-size: 1.4rem;
				color: $black;
				min-height: 7rem;

				&--alt {
					font-size: 1.6rem;
					color: $slate-blue;
					min-height: initial;
				}
			}

			&--alt {
				h2 {
					text-transform: initial;
					min-height: 10rem;
				}
			}

			&--products {
				h2 {
					text-transform: initial;
				}
			}
		}
	}
}
