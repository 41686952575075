.table {
	&__heading {
		width: 90%;
		margin: 2rem auto;

		@include media(">=tablet") {
			width: 40%;
			margin: 2rem auto;
		}

		h2 {
			font-size: 3.5rem;
			text-align: center;
		}
	}

	&__container {
		width: 100%;
		margin: 3rem 0;
		padding-bottom: 3rem;
		display: block;
		overflow: scroll;
		border-collapse: collapse;

		@include media(">=tablet") {
			margin: 0 auto;
			width: fit-content;
			max-width: 104.2rem;
		}

		&::-webkit-scrollbar {
			width: 100%;
			height: 1.1rem;

			@include media(">=tablet") {
				display: none;
			}
		}

		&::-webkit-scrollbar-track {
			background-color: $light-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: $gold;
		}

		a {
			color: $black;

			img {
				vertical-align: middle;
				margin-left: .5rem;
				text-decoration: none;
			}
		}

		.description {
			width: 50%;
		}

		tbody {
			tr {
				text-align: left;

				&:nth-child(odd) {
					background-color: $light-grey;
				}
			}
		}

		th {
			font-size: 1.2rem;
			font-weight: bold;
			text-align: left;
			padding: 1rem;
			text-transform: uppercase;
		}

		td {
			font-size: 1.2rem;
			padding: 1rem;
			min-width: 8rem;
		}
	}
}
