// COLORS
$white: #ffffff;
$black: #000000;
$dark-grey: #53565a;
$light-grey: #EDEDED;
$lighter-grey: #F8F8F8;
$another-grey: #EBEBEB;
$grey: #707070;
$gold: #AA8900;
$slate-blue: #326295;
$light-blue: #418595;
$red: #FF0000;

//FONT-FAMS
$font-serif: 'Bodoni Moda', serif;
$font-sans-serif: 'atten-new', sans-serif;
$font-sans-serif-alt: 'Helvetica Neue', 'Helvetica', sans-serif;

:root {
	--color-border-default: #707070;
	--color-border-light: #E0E0E0;
	--nav-background-height: 200px;
}

html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
	position: relative;
	overflow-x: hidden;

	&.no-scroll {
		overflow: hidden;
	}
}

.hide {
	display: none !important;
	visibility: hidden !important;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}