.forms {

    &__container {
        max-width: 77rem;
        margin: 0 auto;
    }

    &__heading {
        padding: 2rem;
        width: fit-content;
        margin: 0 auto;

        hr {
            width: 8rem;
            height: .4rem;
            background-color: $black;
            margin: 0;

            @include media(">=tablet") {
               margin: 0 auto;
            }
        }

        h2 {
            font-size: 3.5rem;
            color: $black;
            margin: 1rem 0;
            text-transform: uppercase;
        }

        p {
            font-size: 1.6rem;
            margin: 0;
        }
    }

    &__form {
        margin: 1rem 0;
        padding: 0 2rem;

        @include media(">=tablet") {
            flex-direction: row;
            margin: 4rem 0;
        }

        .input-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 0 2rem 0;

            @include media(">=tablet") {
                flex-direction: row;
            }

            &__half {
                width: 100%;
                margin: 2rem 0;
                flex-direction: column;

                @include media(">=tablet") {
                    width: 46%;
                    margin: 0;
                }
            }

            span {
                color: $red;
            }

            &--alt {
                flex-direction: column;
            }

            &--checkbox {
                justify-content: initial;
                align-items: baseline;
                flex-direction: row;

                label {
                    margin-left: 1rem;
                    text-transform: initial;
                }

                a {
                    text-decoration: none;
                }
            }
        }

        label {
            font-size: 1.6rem;
            margin: 1rem 0;
            text-transform: uppercase;
            a {
                font-family: $font-sans-serif-alt;
            }
        }
        
        .form-anchor {
            display: flex;
            width: 10rem;
            margin: 0 auto;

            @include media(">=tablet") {
                margin: 0 0 0 auto;
            }
        }

        input {
            width: 100%;
            height: 5.8rem;
            border: 1px solid #D9D9D9;
            background: #F8F8F8;
        }

        .checkbox {
            width: initial;
            height: initial;
        }

        .message-input {
            min-height: 17rem;
        }
    }
}