.accordion {
	position: relative;
	padding: 2rem;
	background: $lighter-grey;

	@include media(">tablet") {
		background: none;
		display: flex;
		padding: 4rem;

		&::before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: -16rem;
			left: -100%;
			transform: scaleX(200%), translateX(-50%);
			margin: auto;
			background: $lighter-grey;
			z-index: -1;
		}
	}

	@include media(">desktop") {
		padding: 7rem 8rem 7rem 11rem;
	}

	.heading-container {
		position: relative;
		margin: 0;
		padding: 0;

		@include media(">tablet") {
			margin-right: 8rem;
		}
	}

	&__container {
		@include media(">tablet") {
			width: 50%;
		}
	}

	&__item {
		position: relative;
		border-bottom: 1px solid #D9D9D9;
		padding: 3rem 0;

		&:first-child {border-top: 1px solid #D9D9D9;}

		&.active {
			.accordion__copy,
			.accordion__media {
				display: block;
			}

			@include media(">tablet") {
				.accordion__media {
					display: none;
				}
			}
			
			.button__openToggle {
				transform: rotate(45deg);
			}
		}

		@include media(">tablet") {
			margin-right: 8rem;
		}
	}

	&__button {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__title {
		position: relative;
		margin: 0;
		font-size: 2rem;
		font-weight: 700;
		text-transform: uppercase;
		cursor: pointer;

		@include media(">tablet") {
			font-size: 2.3rem;
		}
	}

	&__copy {
		display: none;
	}

	&__media {
		display: none;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__desktop {
		display: none;
		width: 50%;
		align-content: flex-start;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: right;
		}

		@include media(">tablet") {
			display: block;
			max-height: 60rem;
		}
	}
}