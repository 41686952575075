// Primary Site Navigation
.site {
	&-header {
		width: 100%;
		flex-wrap: wrap;
		z-index: 800;
		display: flex;
		flex-direction: column;

		@include media(">tablet") {
			flex-wrap: nowrap;
			justify-content: space-between;

			&.fixed {
				position: fixed;
				top: 0;
			}
		}

		.nav-container-secondary {
			width: 100%;
			display: none;
			flex-direction: row;
			background-color: $dark-grey;
			padding: 2rem 0;

			@include media(">tablet") {
				display: flex;
			}

			.logo-secondary {
				width: 20rem;
				padding: 0 2rem;

				img {
					width: 100%;
					height: auto;
				}
			}

			.nav-secondary {
				width: fit-content;
				margin-left: auto;

				.secondary-link {
					font-size: 1.2rem;
					color: $white;
					text-decoration: none;
					padding: 0 2rem;
					position: relative;

					&::before {
						content: "";
						position: absolute;
						width: 1.6rem;
						height: 1.6rem;
						background-repeat: no-repeat;
						background-size: cover;
						left: 0;
					}

					&--locator {
						&::before {
							background-image: url("/Assets/icons/locator-icon.png");
						}
					}

					&--about {
						&::before {
							background-image: url("/Assets/icons/about-icon.png");
						}
					}

					&--contact {
						&::before {
							background-image: url("/Assets/icons/contact-icon.png");
						}
					}
				}
			}
		}

		.nav-container {
			flex-direction: row;
			z-index: 900;
			background-color: $dark-grey;

			@include media(">tablet") {
				margin: 0;
				padding: 1.5rem 0;
				position: relative;
				align-items: center;
				background-color: $white;
				display: flex;
				justify-content: space-around;
			}

			.search-bar {
				position: relative;
				display: none;
				padding: 1rem;
				background-color: $white;
				padding: 1rem;
				margin-left: auto;

				@include media(">tablet") {
					display: flex;
					align-items: center;
					margin-right: 4rem;
					padding: 1rem 1rem 1rem 5rem;
					background-color: $light-grey;
				}

				&.active {
					display: flex;
					flex-direction: row;
				}

				input {
					width: 85%;
					height: 4rem;
					font-size: 1.4rem;
					border: 1px solid #DBDBDB;
					color: $black;
					font-family: $font-sans-serif;
					background-color: $white;
					padding: 0.5rem 1rem;

					&::placeholder {
						color: $black;
					}

					@include media(">tablet") {
						width: auto;
						background-color: $light-grey;
						border: none;
					}
				}

				.search-sub-button {
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					width: 15%;
					background-color: $slate-blue;
					border: none;

					&::before {
						content: '';
						background-image: url("/Assets/icons/search-arrow.png");
						background-size: cover;
						position: absolute;
						width: 1.1rem;
						height: 1rem;
					}

					@include media(">tablet") {
						display: none;
					}
				}

				&::before {
					content: "";
					width: 2.5rem;
					height: 2.5rem;
					background-image: url("/Assets/icons/search-icon.png");
					background-repeat: no-repeat;
					background-size: cover;
					position: absolute;
					left: 1.5rem;
					display: none;

					@include media(">tablet") {
						display: block;
					}
				}
			}
		}

		&.active {
			position: fixed;
			height: 100%;

			.menu-toggle {
				color: $white;
			}

			.site-nav {
				opacity: 1;
				left: 0;
				z-index: 9999;
			}

			.menu-toggle .menu-icon {
				background: transparent;

				&::before {
					transform: rotate(-45deg);
					top: 0;
					background: $white;
				}

				&::after {
					transform: rotate(45deg);
					top: 0;
					background: $white;
				}
			}
		}

		&.sticky {
			position: fixed;
			top: 0;
			width: 100%;
			border-bottom: 1px solid var(--color-border-alt);
		}
	}

	&-logo {
		display: flex;
		height: 7.5rem;
		border-bottom: 1px solid #dbdbdb;
		position: relative;
		z-index: 999;
		margin: 0;

		@include media(">tablet") {
			height: auto;
			margin: 0;
			border: none;
			display: block;
			flex: 0 0 15%;
			margin-left: 4rem;
		}

		.site-home {
			justify-content: center;
			align-self: center;
			width: 60%;
			height: auto;
			padding: 0 1rem;
			display: none;

			@include media(">tablet") {
				width: auto;
				height: 7.1rem;
				padding: 0;
				display: flex;
				object-fit: contain;

				img {
					width: auto;
					height: 100%;
				}
			}

			&--mobile {
				display: flex;

				@include media(">tablet") {
					display: none;
				}
			}
		}

		.search-button {
			@include media(">tablet") {
				display: none;
			}
		}
	}
}

.menu-toggle {
	display: flex;
	flex: 1 1 15%;
	padding: 2.2rem;
	position: relative;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	border: none;
	border-right: 1px solid #dbdbdb;
	background-color: transparent;

	@include media(">tablet") {
		display: none;
	}

	.menu-icon {
		background: $white;
		display: block;
		height: 0.3rem;
		width: 2rem;
		position: relative;
		transition: 0.2s ease-out;

		&::before {
			background: $white;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0.6rem;
			transition: all 0.2s ease-out;
		}

		&::after {
			background: $white;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: -0.6rem;
			transition: all 0.2s ease-out;
		}
	}
}

.search-button {
	display: flex;
	flex: 1 1 10%;
	padding: 1.6rem;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: none;
	border-left: 1px solid #dbdbdb;

	@include media(">=desktop") {
		flex: 0 0 auto;
	}

	&:hover,
	&.active {
		.glass-icon {
			border-color: $white;

			&::before {
				background-color: $white;
			}
		}
	}
}

.glass-icon {
	color: $white;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	width: 15px;
	height: 15px;
	border: solid 2px $white;
	border-radius: 100%;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);

	@include media(">tablet") {
		display: none;
	}

	&::before {
		content: "";
		position: absolute;
		top: 15px;
		left: 5px;
		height: 8px;
		width: 2px;
		background-color: $white;
	}
}

.site-nav {
	display: flex;
	flex-direction: column;
	flex: 1 0 100%;
	align-content: flex-start;
	width: 88%;
	height: 100%;
	overflow-x: hidden;
	border-right: 1px solid var(--color-border-alt);
	box-shadow: 3px 3px 3px #e6e6e6;
	position: absolute;
	top: 7.6rem;
	left: -100%;
	background: $white;
	opacity: 0;
	transition: all 0.2s ease-in-out;

	@include media(">tablet") {
		// position: relative;
		left: inherit;
		margin: 0;
		top: auto;
		border: none;
		flex: none;
		box-shadow: none;
		opacity: 1;
		display: block;
		width: fit-content;
		height: inherit;
		overflow: unset;
		align-self: center;
		justify-content: center;
		background: none;
		position: unset;
		flex: 1 1 80%;
	}

	@include media(">desktop") {
		margin: 0 4rem;
	}
}

.desktop-nav {
	display: none;

	@include media(">tablet") {
		display: flex;
	}
}

.menu,
.menu__sub {
	margin-top: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.menu {
	position: relative;
	width: 400px;
	height: 100%;
	overflow: hidden;
	padding: 0;

	@include media(">tablet") {
		width: auto;
		height: auto;
		display: flex;
		align-items: center;
		position: unset;

		[aria-haspopup="true"] {
			~ .menu__sub {
				display: none;
			}
		}
	}
}

.menu__item {
	margin: 0;
	padding: 0;
	position: unset;

	&::before {
		display: none;
	}

	@include media(">tablet") {
		&--bar {
			// keep the menu open w/focus
			&:focus-within {
				> [aria-haspopup="true"] {
					~ .menu__sub {
						display: flex;

						> li {
							padding-left: 6rem;
						}
					}
				}
			}

			> .menu__link {
				padding: 3rem 0 0 0;
				margin: 0 .5rem 3rem 2.5rem;
				border-bottom: 5px solid transparent;

				&:hover {
					border-bottom-color: $slate-blue;
				}
			}
		}
	}

	@include media(">desktop") {
		&--bar {
			// keep the menu open w/focus
			&:focus-within {
				> [aria-haspopup="true"] {
					~ .menu__sub {
						display: flex;

						> li {
							padding-left: 6rem;
						}
					}
				}
			}

			> .menu__link {
				padding: 3rem 0 0 0;
				margin: 0 3rem 2.5rem;
				border-bottom: 5px solid transparent;

				&:hover {
					border-bottom-color: $slate-blue;
				}
			}
		}
	}
	

	&--mobile {
		display: block;

		@include media(">tablet") {
			display: none;
		}
	}

	&--search {
		display: none;

		> a {
			display: block;
			padding: 2rem;

			&:hover {
				background-color: transparent !important;
			}
		}

		&:hover,
		&.active {
			.glass-icon {
				border-color: var(--color-brand);

				&::before {
					background-color: var(--color-brand);
				}
			}
		}
	}
}

.menu__link {
	display: inline-block;
	width: 100%;
	padding: 3rem;
	border-bottom: 1px solid #707070;
	font-weight: var(--font-weight-alt);
	font-size: 1.8rem;
	text-decoration: none;
	color: $black;

	@include media(">tablet") {
		border-bottom: none;
		width: auto;
		font-size: 1.4rem;

		&[aria-haspopup="true"] {
			// FUNCTIONALITY: Open mega menu
			// &:focus {
			// 	~ .menu__sub {
			// 		display: flex;
			// 		transform-origin: top;
			// 		animation: dropdown 0.2s ease-out;
			// 	}
			// }
			~ .menu__sub.active {
				display: flex;
				transform-origin: top;
				animation: dropdown 0.2s ease-in-out;
			}

			// FUNCTIONALITY: Keep mega menu open
			~ .menu__sub {
				&:hover {
					display: flex;
				}
			}
		}
	}

	&.sub__close {
		font-size: var(--font-size-default);
		font-weight: var(--font-weight-default);
		padding: 1rem;
		padding-left: 1rem;

		@include media(">tablet") {
			display: none;
		}

		.menu__expand {
			margin-left: 0;
			margin-right: 0.5rem;
			margin-bottom: 0.25rem;
			transform: rotate(180deg);
			width: 2rem;
			height: 2rem;
		}
	}
}

.menu__expand {
	margin-left: 3rem;
	background: url(/Assets/icons/cta-arrow.svg) no-repeat 50%;
	display: inline-block;
	width: 3rem;
	height: 3rem;
	vertical-align: middle;

	@include media(">tablet") {
		display: none;
	}
}

.menu__sub {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: $white;
	opacity: 0;
	visibility: hidden;
	transition: all 0.35s ease-in-out;
	transform: translateX(100%);

	//completely reset for desktop
	@include media(">tablet") {
		top: unset;
		left: unset;
		width: unset;
		flex-direction: column;
		background: white;

		// temp
		visibility: visible;
		opacity: 1;
		transform: none;

		&--mega {
			// LAYOUT: Mega menu
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			background: white;

			&:hover {
				display: flex;
			}

			&::before {
				content: "";
				width: 100vw;
				// height: var(--nav-background-height); //REPLACE WITH JS CALCULATED VALUE
				height: 300px;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: white;
				z-index: -1;
				transition: height .2s ease;
			}

			&.active {
				display: flex;
			}

			.menu__link {
				position: relative;
				padding: 1rem 2rem;
				display: flex;
				justify-content: space-between;

				&.sub__close {
					display: none;
				}

				&:focus {
					background: $another-grey;
				}
			}

			.menu__expand {
				display: inline-block;
				margin-left: 0;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 2.8rem;
				height: 2.8rem;
				border: 1px solid transparent;
				transition: border .2s ease-in-out;

				&:hover {
					border: 1px solid $light-grey;
				}
			}

			.menu__highlight {
				display: flex;
				flex-direction: column;
				padding: 0 2rem;
				width: 50%;
			}

			> li {
				width: 20%;

				> [aria-haspopup="true"] {
					~ .menu__sub {
						left: 25%;
						width: 25%;

						.menu__sub {
							width: 100%;
							left: 100%;
						}
					}
				}
			}

			.menu__sub.menu__sub--final {
				width: 200% !important;
				flex-direction: row;

				ul {
					width: 50%;
				}
			}

			// LAYOUT: Flyouts
			[aria-haspopup="true"] {
				~ .menu__sub,
				& {
					//border-left: 1px solid #f0f0f0;
					// FUNCTIONALITY: Keeping flyouts open
					&:hover {
						display: flex;
					}
				}
				// LAYOUT: Flyouts
				~ .menu__sub {
					position: absolute;
					top: 0;
					height: 100%;
				}
			}
		}
	}

	& > .menu__link {
		padding: 1rem 0;
	}

	&.active {
		opacity: 1;
		visibility: visible;
		transform: translateX(0%);
	}
}
